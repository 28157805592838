import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import firebaseConfig from "./firebaseUtils/firebaseConfig.js";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "serviceWorkerRegistration.ts";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
console.log("firebase config", firebaseConfig);
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
serviceWorkerRegistration.register();
