// App.js
// import { Routes, Route } from "react-router-dom";
import "./App.css";
// import HomePage from "Pages/HomePage/HomePage";
import useVantaEffect from "hooks/useVantaEffect";
import useWindowHeight from "hooks/useWindowHeight";
// import ServicesPage from "Pages/ServicesPage/ServicesPage";
import "animate.css/animate.css";
// import ContactPage from "Pages/ContactPage/ContactPage";
// import VideoPage from "Pages/VideoPage/VideoPage";
// import AppointmentsPage from "Pages/AppointmentsPage/AppointmentsPage";
// import ProgramariPage from "Pages/ProgramariPage/ProgramariPage";
// import SuccesPage from "Pages/SuccesPage/SuccesPage";
import { useEffect } from "react";

function App() {
  const windowHeight = useWindowHeight();
  useVantaEffect("#vanta", windowHeight);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch("/.netlify/functions/submission-created", {
    //       method: "POST", // specify the method
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });
    //     console.log("response", response);
    //     if (response.ok) {
    //       const data = await response.json();
    //       console.log("response data", data);
    //     } else {
    //       console.error("Error fetching data:", response.status);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchData();
  }, []);

  return (
    <div className="App">
      <div id="vanta"></div>
      {/* <Routes>
        <Route exact path="/*" element={<HomePage />} />
        <Route path="/servicii" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/admin" element={<AppointmentsPage />} />
        <Route path="/programari" element={<ProgramariPage />} />
        <Route path="/success" element={<SuccesPage />} />
      </Routes> */}
    </div>
  );
}

export default App;
