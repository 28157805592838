// useVantaEffect.js
import { useEffect, useState } from "react";
import CLOUDS from "vanta/src/vanta.clouds";

const useVantaEffect = (elSelector) => {
  const [vantaEffect, setVantaEffect] = useState(null);

  useEffect(() => {
    const element = document.querySelector(elSelector);

    if (element && !vantaEffect) {
      let minHeight;
      const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

      if (isMobileDevice) {
        minHeight = window.screen.height;
      } else {
        minHeight = window.innerHeight;
      }

      setVantaEffect(
        CLOUDS({
          el: element,
          speed: 1,
          minHeight,
          minWidth: 200.0,
        })
      );

      return () => {
        if (vantaEffect) {
          vantaEffect.destroy();
        }
      };
    }
  }, [elSelector, vantaEffect]);

  return vantaEffect;
};

export default useVantaEffect;
