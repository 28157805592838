const firebaseConfig = {
  apiKey: "AIzaSyCTIbF9Jo-mKBfEidmY90j8xUWJN5u9yGc",
  authDomain: "epsiholog-webapp.firebaseapp.com",
  projectId: "epsiholog-webapp",
  storageBucket: "epsiholog-webapp.appspot.com",
  messagingSenderId: "325422411635",
  appId: "1:325422411635:web:29a61c88dfd056b9399f06",
  measurementId: "G-KEF6DPHCB4",
};

export default firebaseConfig;
